<template>
  <div>
    <div class="align-center text-center mt-6 mx-6">
      <v-card elevation="6" style="margin: auto" max-width="2000">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>{{ $t("tables.eventsList") }}</v-toolbar-title>
        </v-toolbar>
        <div class="pl-4 pr-4 pt-2 pb-2">
          <v-card>
            <div v-if="$i18n.locale == 'en'" class="pl-4 pr-4 pt-2 pb-2">
              <v-data-table
                :headers="headers"
                :search="search"
                :items="events"
                :items-per-page="10"
                fixed-header
                class="elevation-1"
                :loading="this.myloadingvariable"
                loading-text="Loading..."
              >
              </v-data-table>
            </div>

            <div v-if="$i18n.locale == 'pt'" class="pl-4 pr-4 pt-2 pb-2">
              <v-data-table
                :headers="headersPt"
                :search="search"
                :items="events"
                :items-per-page="10"
                fixed-header
                class="elevation-1"
                :loading="this.myloadingvariable"
                loading-text="Loading..."
              >
              </v-data-table>
            </div>
          </v-card>
        </div>

        <div>
          <v-row class="ma-4" v-if="userServices.advPortal">
            <!-- DATE PICKER -->
            <v-col cols="3">
              <v-card
                outlined
                style="
                  border-width: 1px;
                  border-color: #757575;
                  max-width: 250px;
                  max-height: 55px;
                "
              >
                <v-col cols="12" sm="11" md="11" xl="11" class="pa-0">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="dateRange"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateRange"
                        :label="$t('button.dateRange')"
                        persistent-hint
                        prepend-icon="date_range"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateRange"
                      range
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.menu.save(dateRange);
                          passDateRangeToEventTable();
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-select
                v-if="userServices.advPortal"
                class="d-flex justify-center"
                style="max-width: 200px; max-height: 30px"
                :items="periodsToSelect"
                v-model="timePeriod"
                @change="updateTimePeriod(timePeriod)"
                :label="$t('button.chooseTimePeriod')"
                outlined
              ></v-select>
            </v-col>

            <!--SEARCH BAR-->
            <v-col cols="6">
              <v-card-title class="mt-0 pt-0">
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  outlined
                  hide-details
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
    <div v-if="userServices.csvExport">
      <vue-json-to-csv :json-data="this.events">
        <v-btn elevation="2" class="mb-2" color="#BDBDBD">
          <b>{{ $t("button.exportToCSV") }}</b>
        </v-btn>
      </vue-json-to-csv>
    </div>
  </div>
</template>

<script>
import EventService from "@/services/EventService";
import HelperMethods from "@/utilities/HelperMethods";
import moment from "moment";
import { mapState } from "vuex";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  data() {
    return {
      userServices: {},
      events: [],
      devices: [],
      headers: [
        // { text: "Event Id", value: "id" },
        { text: "Date", value: "createdAt" },
        { text: "Description", value: "description" },
      ],
      headersPt: [
        // { text: "Id Evento", value: "id" },
        { text: "Data", value: "createdAt" },
        { text: "Descrição", value: "description" },
      ],
      typesOfEvent: ["critical warning", "warning"],
      typesOfEventPt: ["alerta critico", "alerta"],
      typesOfData: [
        "Temperature",
        "Carbon Dioxide",
        "Noise",
        "Humidity",
        "Light Intensity",
        "Air Quality",
        "Pressure",
        "Wifi Signal",
        "Light Temperature",
        "Number of Devices",
        "People Flow",
        "Disconnected",
        "Reconnected",
      ],
      typesOfDataPt: [
        "Temperatura",
        "Dióxido de Carbono",
        "Ruído",
        "Humidade",
        "Intensidade da Luz",
        "Qualidade do Ar",
        "Pressão",
        "Sinal Wifi",
        "Temperatura da Luz",
        "Número de Dispositivos",
        "Fluxo de Pessoas",
        "Desconectou",
        "Reconectou",
      ],
      periodsToSelect: ["Daily", "Weekly", "Monthly"],
      periodsToSelectPt: ["Daily", "Weekly", "Monthly"],
      myloadingvariable: false,
      action: "",
      message: "",
      timePeriod: "",
      dateTo: null,
      dateFrom: null,
      entryId: null,
      search: null,
      groupId: null,
      error: null,
      success: null,

      // Calendar Variables
      dateRange: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      menu: false,
    };
  },
  components: {
    VueJsonToCsv,
  },
  methods: {
    async getTableEntries() {
      let self = this;
      this.myloadingvariable = true;
      try {
        const response = await EventService.getEvents(
          this.groupId,
          this.dateFrom,
          this.dateTo
        );
        console.log("events: ", response);
        this.events = response.data;
      } catch (err) {
        console.log(err);
      }
      this.myloadingvariable = false;

      function buildDescriptionString(
        deviceName,
        eventType,
        dataType,
        condition,
        value,
        readValue
      ) {
        let dataMeasure = HelperMethods.defineDataMeasure(dataType);

        return (
          deviceName +
          " triggered a " +
          eventType +
          " due to " +
          dataType +
          " " +
          condition +
          " " +
          value +
          " " +
          dataMeasure +
          " at " +
          readValue +
          " " +
          dataMeasure +
          "."
        );
      }

      function buildDescriptionStringPt(
        deviceName,
        eventType,
        dataType,
        condition,
        value,
        readValue
      ) {
        let dataMeasure = HelperMethods.defineDataMeasure(dataType);

        return (
          deviceName +
          " lançou um " +
          eventType +
          " devido a " +
          dataType +
          " " +
          condition +
          " " +
          value +
          " " +
          dataMeasure +
          " com " +
          readValue +
          " " +
          dataMeasure +
          "."
        );
      }

      function buildDisconnectDescriptionString(
        deviceName,
        eventType,
        dataType
      ) {
        return (
          deviceName + " triggered a " + eventType + " due to " + dataType + "."
        );
      }

      function buildDisconnectDescriptionStringPt(
        deviceName,
        eventType,
        dataType
      ) {
        return (
          deviceName + " lançou um " + eventType + " devido a " + dataType + "."
        );
      }

      this.events.forEach(function (value) {
        value.createdAt = HelperMethods.formatTimestamp(value["createdAt"]);
        value.deviceName = HelperMethods.nameDevice(
          value["refDevice"],
          self.devices
        );
        value.condition = HelperMethods.convertCondition(value["condition"]);
        value.dataType = HelperMethods.nameDataType(
          value["dataType"],
          self.$i18n.locale,
          self.typesOfData,
          self.typesOfDataPt
        );
        value.eventType = HelperMethods.nameEventType(
          value["eventType"],
          self.$i18n.locale,
          self.typesOfEvent,
          self.typesOfEventPt
        );
        if (
          self.$i18n.locale == "pt" &&
          value.dataType !== "Desconectou" &&
          value.dataType !== "Reconectou"
        ) {
          value.description = buildDescriptionStringPt(
            value["deviceName"],
            value["eventType"],
            value["dataType"],
            value["condition"],
            value["value"],
            value["readValue"]
          );
        } else if (
          self.$i18n.locale == "en" &&
          value.dataType !== "Disconnected" &&
          value.dataType !== "Reconnected"
        ) {
          value.description = buildDescriptionString(
            value["deviceName"],
            value["eventType"],
            value["dataType"],
            value["condition"],
            value["value"],
            value["readValue"]
          );
        } else if (
          self.$i18n.locale == "en" &&
          (value.dataType === "Disconnected" ||
            value.dataType === "Reconnected")
        ) {
          value.description = buildDisconnectDescriptionString(
            value["deviceName"],
            value["eventType"],
            value["dataType"]
          );
        } else if (
          self.$i18n.locale == "pt" &&
          (value.dataType === "Desconectou" ||
            value.dataType === "Reconectou")
        ) {
          value.description = buildDisconnectDescriptionStringPt(
            value["deviceName"],
            value["eventType"],
            value["dataType"]
          );
        }
      });
    },

    passDateRangeToEventTable() {
      if (this.dateRange.length === 1) {
        this.dateFrom = new Date(this.dateRange[0]);
        this.dateTo = new Date(Date.now());
        this.getTableEntries();
        return;
      }

      let date1 = new Date(this.dateRange[0]);
      let date2 = new Date(this.dateRange[1]);

      if (date1 > date2) {
        this.dateFrom = date2;
        this.dateTo = date1;
        this.getTableEntries();
        return;
      } else if (date2 > date1) {
        this.dateFrom = date1;
        this.dateTo = date2;
        this.getTableEntries();
        return;
      } else {
        this.dateFrom = date1;
        this.dateTo = new Date(Date.now());
        this.getTableEntries();
        return;
      }
    },

    updateTimePeriod(timePeriod) {
      this.dateTo = moment().add(1, "days").format("YYYY-MM-DD");
      switch (timePeriod) {
        case "Daily":
          this.dateFrom = moment().subtract(1, "days").format("YYYY-MM-DD");
          break;
        case "Weekly":
          this.dateFrom = moment().subtract(1, "weeks").format("YYYY-MM-DD");
          break;
        case "Monthly":
          this.dateFrom = moment().subtract(1, "months").format("YYYY-MM-DD");
          break;
      }
      this.getTableEntries();
    },
  },
  computed: {
    ...mapState(["userRole"]),
  },
  async mounted() {
    this.userServices = this.$store.getters.userServices;
    this.groupId = this.$store.getters.groupId;
    this.devices = this.$store.getters.devices;

    this.dateTo = moment().add(1, "days").format("YYYY-MM-DD");
    this.dateFrom = moment().subtract(1, "days").format("YYYY-MM-DD");

    this.getTableEntries();
  },
};
</script>

<style></style>
